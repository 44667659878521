<template>
    <Wrapper />
</template>

<script>
import './index.less'
import Wrapper from '@/components/wrapper/Wrapper'

export default {
    name: 'Index',
    components: {
        Wrapper,
    },
}
</script>